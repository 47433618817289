import { useState, useEffect } from "react";
import { redirect, useLoaderData, useLocation } from "react-router-dom";

import { MobileView, BrowserView } from "react-device-detect";
import RoutineTaskFilter from "../../components/RoutineTask/Filter";

import { RiCalendar2Line } from "react-icons/ri";
import ListWrapper from "../../UI/ListWrapper";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";

import List from "../../components/RoutineTask/List";

import { getLocalStorageData } from "../../util/auth";

import useRoutineTaskFilterStore from "../../store/filters/routine-tasks";
import useSidebarStore from "../../store/sidebar";

const RoutineTasks = () => {
  const location = useLocation();
  const { setLeftSidebarContent } = useSidebarStore();
  const tasks = useLoaderData();
  const filterStore = useRoutineTaskFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetch();
  }, [location]);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <RoutineTaskFilter items={tasks} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, tasks]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <RiCalendar2Line /> Регламентные задания
      </>
    );
  };

  return (
    <ListWrapper
      title={title}
      filteredList={filterStore.filteredList}
      filterStore={filterStore}
      onUpdatePage={filterStore.fetch}
      secondaryList={tasks}
      isLoading={filterStore.isLoading}
      hideLengthRow
      addRoute="/routine-tasks/add"
    >
      <MobileView>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
          <Offcanvas.Body>
            <RoutineTaskFilter
              items={tasks}
              setShowOffcanvas={setShowOffcanvas}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="justify-content-between my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row className="justify-content-between my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
        </Row>
      </BrowserView>
      <List items={filterStore.filteredList}></List>
    </ListWrapper>
  );
};

export default RoutineTasks;

export async function loader() {
  document.title = "РЕГЛАМЕНТНЫЕ ЗАДАНИЯ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/routine-tasks`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const id = data.get("id");

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/routine-tasks/delete/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect("/routine-tasks");
}
