import { useState, useContext } from "react";

import { formatDateTime } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";

import { AddToCalendar } from "../../components/Work/AddToCalendar";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import ScheduledWorkForm from "./ScheduledWorkForm";

import { AuthedUserContext } from "../../store/authed-user-context";
import ConfirmScheduledWork from "./ConfirmScheduled";

function ScheduledWorkItem({
  work,
  ticket,
  company,
  responsibles,
  works,
  setWorks,
  otherCompanyTickets,
}) {
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const [isNew, setIsNew] = useState(
    new Date() - new Date(work.createdAt) < 10000 ? true : false,
  );

  const isOverdue = new Date(work.planningToStart) < new Date();

  const bgClassName = isNew
    ? "bg-success bg-opacity-10"
    : isOverdue
      ? "bg-danger bg-opacity-10"
      : "bg-warning bg-opacity-10";

  setTimeout(() => {
    setIsNew(false);
  }, 15000);

  return (
    <>
      <Card className={`shadow-sm mb-3 ${bgClassName}`}>
        <Card.Body>
          <Row className="mb-2">
            <Col>
              <strong>{`${work?.executor?.lastName} ${work?.executor?.firstName}`}</strong>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {!work.visitRequired
                ? "Запланированы удалённые работы "
                : "Запланирован выезд "}
              на <strong>{formatDateTime(work.planningToStart)}</strong>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              Предварительная длительность{" "}
              <strong>
                {msToHMS(
                  new Date(work.planningToFinish) -
                    new Date(work.planningToStart),
                )}
              </strong>
            </Col>
          </Row>
          <Row className="justify-content-end align-items-end">
            <Col className="mb-3">
              <AddToCalendar
                ticket={ticket}
                start={work.planningToStart}
                finish={work.planningToFinish}
              />
            </Col>
            <Col xs="auto" className="text-end">
              <Row>
                {(isAdmin ||
                  work.createdBy?._id.toString() === userId.toString()) && (
                  <Col xs="auto">
                    <ScheduledWorkForm
                      title="Изменить запланированные работы"
                      buttonStyle="updateItem"
                      action={`/works/update/${work._id.toString()}`}
                      work={work}
                      ticket={ticket}
                      company={company}
                      responsibles={responsibles}
                      works={works}
                      otherCompanyTickets={otherCompanyTickets}
                    />
                  </Col>
                )}
                {(isAdmin ||
                  work.executor?._id.toString() === userId.toString()) && (
                  <Col xs="auto">
                    <ConfirmScheduledWork
                      title="Подтвердить запланированные работы"
                      action={`/works/update/${work._id.toString()}`}
                      work={work}
                      ticket={ticket}
                      company={company}
                      responsibles={responsibles}
                      works={works}
                      otherCompanyTickets={otherCompanyTickets}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ScheduledWorkItem;
