import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import { getLocalStorageData } from "../../util/auth";

import Table from "react-bootstrap/Table";

import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { formatPrice } from "../../util/format-string";
import { RiDraftLine } from "react-icons/ri";
import UnrelatedWorksOffcanvas from "../../components/Finances/UnrelatedWorksOffcanvas";
import DetailedViewOffcanvas from "../../components/Finances/DetailedViewOffcanvas";

const MonthlySummaryReport = () => {
  const data = useLoaderData();

  const filterUnrelatedWorks = (works, tickets, servicePlans) => {
    return works.filter((work) =>
      work.tickets.some((ticketId) =>
        tickets.find(
          (ticket) =>
            ticket._id === ticketId &&
            !servicePlans.some((servicePlan) =>
              servicePlan.ticketCategories
                .map((category) => category._id.toString())
                .includes(ticket.category._id.toString()),
            ),
        ),
      ),
    );
  };

  const splitDataByMonth = (data) => {
    const monthArrays = {};

    data.forEach((company) => {
      company.works.forEach((work) => {
        if (work.finishedAt) {
          const date = new Date(work.finishedAt);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

          if (!monthArrays[monthKey]) {
            monthArrays[monthKey] = data.map((comp) => ({
              ...comp,
              works: [],
              tickets: [],
            }));
          }

          const companyIndex = monthArrays[monthKey].findIndex(
            (c) => c.company === company.company,
          );
          if (companyIndex !== -1) {
            monthArrays[monthKey][companyIndex].works.push(work);

            // Add associated tickets
            work.tickets.forEach((ticketId) => {
              const ticket = company.tickets.find((t) => t._id === ticketId);
              if (
                ticket &&
                !monthArrays[monthKey][companyIndex].tickets.some(
                  (t) => t._id === ticketId,
                )
              ) {
                monthArrays[monthKey][companyIndex].tickets.push(ticket);
              }
            });
          }
        }
      });
    });

    // Remove companies with no works
    Object.keys(monthArrays).forEach((monthKey) => {
      monthArrays[monthKey] = monthArrays[monthKey].filter(
        (company) => company.works.length > 0,
      );
    });

    return Object.values(monthArrays);
  };

  const splitData = splitDataByMonth(data);

  const getMonthName = (UtcDate) => {
    return new Date(UtcDate)
      .toLocaleDateString("ru-Ru", { month: "long" })
      .toUpperCase();
  };

  const calculateWorkTime = (schedule, works, tariffingPeriod) => {
    let worktime = 0;
    let worktimeWorks = [];
    for (let work of works) {
      const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const startedAt = new Date(work.startedAt);
      const finishedAt = new Date(work.finishedAt);

      // If start and end times are the same, skip this work
      if (startedAt.getTime() === finishedAt.getTime()) {
        continue;
      }

      let currentDate = new Date(
        startedAt.getFullYear(),
        startedAt.getMonth(),
        startedAt.getDate(),
      );
      const endDate = new Date(
        finishedAt.getFullYear(),
        finishedAt.getMonth(),
        finishedAt.getDate(),
      );
      let totalWorkTime = 0;

      if (work.withinPlan) {
        // If work is within plan, count the entire duration as work time
        totalWorkTime = finishedAt.getTime() - startedAt.getTime();
      } else {
        while (currentDate <= endDate) {
          const dayName = daysOfWeek[(currentDate.getDay() + 6) % 7];
          const daySchedule = schedule[dayName];

          if (daySchedule && daySchedule.isWorking) {
            const [startHour, startMinute] = daySchedule.start
              .split(":")
              .map(Number);
            const [endHour, endMinute] = daySchedule.end.split(":").map(Number);
            const workStart = new Date(currentDate).setHours(
              startHour,
              startMinute,
              0,
              0,
            );
            const workEnd = new Date(currentDate).setHours(
              endHour,
              endMinute,
              0,
              0,
            );

            const dayStart = new Date(
              Math.max(currentDate.getTime(), startedAt.getTime()),
            );
            const dayEnd = new Date(
              Math.min(
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate(),
                  23,
                  59,
                  59,
                  999,
                ),
                finishedAt.getTime(),
              ),
            );

            // Calculate work time within working hours
            const effectiveStart = Math.max(dayStart.getTime(), workStart);
            const effectiveEnd = Math.min(dayEnd.getTime(), workEnd);

            if (effectiveEnd > effectiveStart) {
              totalWorkTime += effectiveEnd - effectiveStart;
            }
          }

          currentDate.setDate(currentDate.getDate() + 1);
        }
      }

      totalWorkTime =
        Math.ceil(totalWorkTime / (tariffingPeriod * 60 * 1000)) *
        (tariffingPeriod * 60 * 1000);

      worktime += Math.round(totalWorkTime / (1000 * 60));

      if (totalWorkTime > 0) {
        worktimeWorks.push(work);
      }
    }
    return { worktime: worktime, worktimeWorks: worktimeWorks };
  };

  const calculateOvertime = (schedule, works, tariffingPeriod) => {
    let overtime = 0;
    let overtimeWorks = [];

    for (let work of works) {
      const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const startedAt = new Date(work.startedAt);
      const finishedAt = new Date(work.finishedAt);

      // Если время начала и окончания совпадают, возвращаем 0
      if (startedAt.getTime() === finishedAt.getTime() || work.withinPlan) {
        continue;
      }

      let currentDate = new Date(
        startedAt.getFullYear(),
        startedAt.getMonth(),
        startedAt.getDate(),
      );
      const endDate = new Date(
        finishedAt.getFullYear(),
        finishedAt.getMonth(),
        finishedAt.getDate(),
      );
      let totalOvertime = 0;

      while (currentDate <= endDate) {
        const dayName = daysOfWeek[(currentDate.getDay() + 6) % 7];
        const daySchedule = schedule[dayName];

        if (daySchedule && daySchedule.isWorking) {
          const [startHour, startMinute] = daySchedule.start
            .split(":")
            .map(Number);
          const [endHour, endMinute] = daySchedule.end.split(":").map(Number);
          const workStart = new Date(currentDate).setHours(
            startHour,
            startMinute,
            0,
            0,
          );
          const workEnd = new Date(currentDate).setHours(
            endHour,
            endMinute,
            0,
            0,
          );

          const dayStart = new Date(
            Math.max(currentDate.getTime(), startedAt.getTime()),
          );
          const dayEnd = new Date(
            Math.min(
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23,
                59,
                59,
                999,
              ),
              finishedAt.getTime(),
            ),
          );

          // Переработка до начала рабочего дня
          if (dayStart < new Date(workStart)) {
            totalOvertime += Math.min(
              new Date(workStart) - dayStart,
              dayEnd - dayStart,
            );
          }

          // Переработка после окончания рабочего дня
          if (dayEnd > new Date(workEnd)) {
            totalOvertime += dayEnd - Math.max(new Date(workEnd), dayStart);
          }
        } else {
          // Если день нерабочий, все время считается переработкой
          const dayStart = new Date(
            Math.max(currentDate.getTime(), startedAt.getTime()),
          );
          const dayEnd = new Date(
            Math.min(
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23,
                59,
                59,
                999,
              ),
              finishedAt.getTime(),
            ),
          );
          totalOvertime += dayEnd - dayStart;
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      totalOvertime =
        Math.ceil(totalOvertime / (tariffingPeriod * 60 * 1000)) *
        (tariffingPeriod * 60 * 1000);

      overtime = overtime + Math.round(totalOvertime / (1000 * 60));
      if (totalOvertime > 0 && !work.withinPlan) {
        overtimeWorks.push(work);
      }
    }
    return { overtime: overtime, overtimeWorks: overtimeWorks };
  };

  const hourPackagePrice = (schedule, relatedWorks, plan) => {
    const workingTime = calculateWorkTime(
      schedule,
      relatedWorks,
      plan.tariffing.period,
    ).worktime;

    const workingTimeHours = workingTime / 60;

    let hourPackageHours = 0;
    let hourPackagePrice = 0;

    for (let hourPackage of plan.tariffing.hourPackage.packages) {
      if (workingTimeHours <= hourPackage.hours) {
        hourPackageHours = hourPackage.hours;
        hourPackagePrice = hourPackageHours * hourPackage.pricePerHour;
        break;
      }
    }

    if (
      hourPackageHours === 0 &&
      plan.tariffing.hourPackage.packages.length > 0
    ) {
      const lastPackage =
        plan.tariffing.hourPackage.packages[
          plan.tariffing.hourPackage.packages.length - 1
        ];
      hourPackageHours =
        lastPackage.hours + (workingTimeHours - lastPackage.hours);
      hourPackagePrice = hourPackageHours * lastPackage.pricePerHour;
    }

    return hourPackagePrice;
  };

  return (
    <>
      <h1 className="display-4">
        <RiDraftLine /> Сводный отчёт по оказанным услугам
      </h1>
      <hr></hr>
      {splitData.map((month) => (
        <Row
          key={getMonthName(month[0].works[0].finishedAt)}
          className="px-4 py-4"
        >
          <h3>{getMonthName(month[0].works[0].finishedAt)}</h3>
          <Table className="ms-1" bordered>
            <thead>
              <tr>
                <th>Услуга</th>
                <th>Тариф</th>
                <th className="text-end">Оплата рамках тарифа</th>
                <th className="text-end">Доп. оплата</th>
                <th className="text-end">Итого</th>
                <th>Действия</th>
              </tr>
            </thead>
            {month.map((data) => {
              let totalPrice = 0;
              let totalAdditionalPrice = 0;

              const unrelatedWorks = filterUnrelatedWorks(
                data.works,
                data.tickets,
                data.servicePlans,
              );
              return (
                <tbody key={data.company._id.toString()}>
                  <tr className="table-light">
                    <td colSpan={6}>
                      <strong>{data.company.fullTitle}</strong>
                    </td>
                  </tr>
                  {data.servicePlans.map((plan) => {
                    const tariff = plan.tariffing.type;

                    const schedule = plan.companyWorkSchedule
                      ? data.company.workSchedule
                      : plan.customProvisionSchedule;

                    const relatedWorks = data.works.filter((work) =>
                      work.tickets.some((ticketId) =>
                        data.tickets.find(
                          (ticket) =>
                            ticket._id === ticketId &&
                            plan.ticketCategories
                              .map((category) => category._id.toString())
                              .includes(ticket.category._id.toString()),
                        ),
                      ),
                    );

                    const hourlyPrice =
                      (calculateWorkTime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).worktime *
                        plan.tariffing.hourly.pricePerHour) /
                      60;

                    const fixedPrice = plan.tariffing.fixedPrice.price;

                    const price =
                      tariff === "hourPackage"
                        ? hourPackagePrice(schedule, relatedWorks, plan)
                        : tariff === "hourly"
                          ? hourlyPrice
                          : fixedPrice;

                    totalPrice += price;

                    const hourlyAdditionalPrice =
                      (calculateOvertime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).overtime *
                        plan.tariffing.hourly.pricePerHourNonWorking) /
                      60;

                    const fixedPriceAdditionalPrice =
                      (calculateOvertime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).overtime *
                        plan.tariffing.fixedPrice.pricePerHourNonWorking) /
                      60;

                    const hourPackageAdditionalPrice =
                      plan.tariffing.hourPackage.nonWorkingTime.type ===
                      "separatePayment"
                        ? (calculateOvertime(
                            schedule,
                            relatedWorks,
                            plan.tariffing.period,
                          ).overtime /
                            60) *
                          plan.tariffing.hourPackage.nonWorkingTime.pricePerHour
                        : 0;

                    const additionalPrice =
                      tariff === "hourly"
                        ? hourlyAdditionalPrice
                        : tariff === "fixedPrice"
                          ? fixedPriceAdditionalPrice
                          : hourPackageAdditionalPrice;

                    totalAdditionalPrice += additionalPrice;

                    const sum = price + additionalPrice;

                    return (
                      <tr key={plan._id}>
                        <td>{plan.title}</td>
                        <td>
                          {plan.tariffing.type === "hourPackage" &&
                            "Пакеты часов"}
                          {plan.tariffing.type === "hourly" &&
                            "Почасовая оплата"}
                          {plan.tariffing.type === "fixedPrice" &&
                            "Фиксированная оплата"}
                        </td>
                        <td className="text-end">{formatPrice(price)}</td>
                        <td className="text-end">
                          {formatPrice(additionalPrice)}
                        </td>
                        <td className="text-end">{formatPrice(sum)}</td>
                        <td>
                          <DetailedViewOffcanvas
                            worktimeWorks={
                              calculateWorkTime(
                                schedule,
                                relatedWorks,
                                plan.tariffing.period,
                              ).worktimeWorks
                            }
                            overtimeWorks={
                              calculateOvertime(
                                schedule,
                                relatedWorks,
                                plan.tariffing.period,
                              ).overtimeWorks
                            }
                          />
                          {unrelatedWorks.length > 0 && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  В списке не должно быть выполненных работ, не
                                  привязанных ни к одной услуге
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  variant="success"
                                  disabled
                                  style={{ pointerEvents: "none" }}
                                >
                                  Утвердить
                                </Button>
                              </span>
                            </OverlayTrigger>
                          )}
                          {unrelatedWorks.length === 0 && (
                            <Button size="sm" variant="success">
                              Утвердить
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    {unrelatedWorks.length > 0 && (
                      <td
                        colSpan={2}
                        className="py-1 bg-danger bg-warning-subtle"
                      >
                        <UnrelatedWorksOffcanvas
                          unrelatedWorks={unrelatedWorks}
                        />
                      </td>
                    )}
                    {unrelatedWorks.length === 0 && <td colSpan={2}></td>}
                    <td className="text-end">
                      <strong>{formatPrice(totalPrice)}</strong>
                    </td>
                    <td className="text-end">
                      <strong>{formatPrice(totalAdditionalPrice)}</strong>
                    </td>
                    <td className="text-end">
                      <strong>{formatPrice(totalPrice + 0)}</strong>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Row>
      ))}
    </>
  );
};

export default MonthlySummaryReport;

export async function loader() {
  document.title = "ОТЧЁТ ПО РАБОТАМ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/finances/monthly-summary-report`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return await response.json();
}
