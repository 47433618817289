import CommentItem from "./Item";
import AddComment from "./Add";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

const CommentsList = (props) => {
  return (
    <>
      <AddComment ticket={props.ticket} />
      {props.comments.length > 0 && (
        <>
          {props.comments
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((comment) => (
              <Row key={comment._id.toString()} className="mb-3">
                <Col>
                  <CommentItem comment={comment} />
                </Col>
              </Row>
            ))}
        </>
      )}
      {!props.comments.length && (
        <Alert variant="light">{"Ноу комментс :("}</Alert>
      )}
    </>
  );
};

export default CommentsList;
