import { create } from "zustand";

import { getLocalStorageData } from "../../util/auth";

// функция последовательно отсеивает заявки согласно активным фильтрам
const taskFilter = (state) => {
  const originalList = state.originalList ? state.originalList : [];
  return originalList
    .filter((task) => {
      if (state.companies?.length > 0) {
        return state.companies.includes(task.company._id.toString());
      } else {
        return true;
      }
    })
    .filter((task) => {
      if (state.categories?.length > 0) {
        return state.categories.includes(task.category._id.toString());
      } else {
        return true;
      }
    })
    .filter((task) => {
      if (state.isActive) {
        return task.isActive;
      } else {
        return true;
      }
    })
    .filter((task) => {
      switch (state.checklist) {
        case "present":
          return task.checklist?.length > 0;
        case "abcent":
          return !task.checklist || task.checklist.length === 0;
        default:
          return true;
      }
    })
    .filter((item) => {
      if (state.searchTerm.length > 0) {
        return [
          item.title,
          item.description,
          item.company?.alias,
          item.category?.title,
          ...item.checklist.flatMap((checklistItem) => [
            checklistItem.description,
          ]),
        ]
          .join(" ")
          .toLowerCase()
          .includes(state.searchTerm);
      } else {
        return true;
      }
    });
};

const searchItems = (query, items) => {
  if (!query) return items;

  // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
  const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

  return items.filter((item) => {
    const fieldsToSearch = [
      item.title,
      item.description,
      item.company?.alias,
      item.category?.title,
      ...item.checklist.flatMap((checklistItem) => [checklistItem.description]),
    ];

    return queryTerms.every((term) =>
      fieldsToSearch.some(
        (field) => field && field.toLowerCase().includes(term),
      ),
    );
  });
};

const useRoutineTaskFilterStore = create((set) => ({
  isActive: false,
  checklist: "any",
  companies: [],
  categories: [],
  searchTerm: "",
  originalList: [],
  filteredList: [],
  fullTextSearch: (query) =>
    set((state) => ({ filteredList: searchItems(query, taskFilter(state)) })),
  isLoading: false,
  fetch: async () => {
    set({ isLoading: true });
    const { token } = getLocalStorageData();
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/routine-tasks`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
    const data = await response.json();
    set({
      originalList: data,
      isLoading: false,
    });
  },
  updateFilter: (data) =>
    set(() => {
      return {
        isActive: data.isActive,
        checklist: data.checklist,
        companies: data.companies,
        categories: data.categories,
        searchTerm: data.searchTerm,
        originalList: data.originalList,
        isLoading: false,
      };
    }),

  applyFilter: () => set((state) => ({ filteredList: taskFilter(state) })),
  resetFilter: () => {
    set((state) => ({
      responsibles: [],
      isActive: false,
      checklist: "any",
      companies: [],
      categories: [],
      searchTerm: "",
    }));
    set((state) => ({
      filteredList: taskFilter(state),
    }));
  },
}));

export default useRoutineTaskFilterStore;
