import { useState, useRef } from "react";
import { useLoaderData } from "react-router-dom";

import { RiArchiveLine } from "react-icons/ri";

import useHttp from "../../hooks/use-http";

import Select from "../../UI/Select";
import Transitions from "../../animations/Transition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import Spinner from "../../animations/Spinner";

import { formatShortDate } from "../../util/format-date";
import { getLocalStorageData } from "../../util/auth";

const TicketsArchive = () => {
  const { token, isEndUser } = getLocalStorageData();

  const data = useLoaderData();
  const formData = data ? data : {};

  const fromInputRef = useRef();
  const toInputRef = useRef();

  const [tickets, setTickets] = useState([]);
  const [company, setCompany] = useState(
    formData.companies?.length === 1 ? formData.companies[0] : {},
  );
  const [responsible, setResponsible] = useState([
    formData.responsibles?.length === 1 ? formData.responsibles[0] : [],
  ]);

  const companyChangeHandler = (selectedItem) => {
    setCompany(selectedItem);
  };

  const responsibleChangeHandler = (selectedItem) => {
    setResponsible(selectedItem);
  };

  const { isLoading, sendRequest: filterTicketsHandler } = useHttp();

  const submitHandler = (event) => {
    event.preventDefault();

    const filterData = {
      from: fromInputRef.current.value,
      to: toInputRef.current.value,
      company: company._id,
      responsible: responsible._id,
    };

    filterTicketsHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/tickets/closed`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: filterData,
      },
      (data) => {
        setTickets(
          data.tickets.map((ticket) => {
            return {
              _id: ticket._id,
              num: ticket.num,
              title: ticket.title,
              applicant: ticket.applicant,
              responsibles: ticket.responsibles,
              createdAt: ticket.createdAt,
              finishedAt: ticket.finishedAt,
            };
          }),
        );
      },
    );
  };

  return (
    <Transitions>
      <h1 className="display-4">
        <RiArchiveLine /> Архив заявок
      </h1>
      <hr></hr>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col sm="auto">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="company">Компания</Form.Label>
              <Select
                id="company"
                placeholder="Выберите компанию"
                required={formData.companies.length === 1 ? false : true}
                isClearable={formData.companies.length === 1 ? false : true}
                isSearchable
                defaultValue={
                  formData.companies?.length === 1 ? formData.companies[0] : ""
                }
                options={formData.companies}
                getOptionLabel={(option) => `${option.alias}`}
                getOptionValue={(option) => option._id}
                onChange={companyChangeHandler}
              />
            </Form.Group>
          </Col>
          {!isEndUser && (
            <Col sm="auto">
              <Form.Group className="mb-3">
                <Form.Label htmlFor="responsibles">Ответственные</Form.Label>
                <Select
                  id="responsibles"
                  placeholder="Выберите пользователей"
                  isClearable={
                    formData.responsibles.length === 1 ? false : true
                  }
                  isSearchable
                  options={formData.responsibles}
                  defaultValue={
                    formData.responsibles?.length === 1
                      ? formData.responsibles[0]
                      : ""
                  }
                  getOptionLabel={(option) =>
                    `${option.lastName} ${option.firstName}`
                  }
                  getOptionValue={(option) => option._id}
                  onChange={responsibleChangeHandler}
                />
              </Form.Group>
            </Col>
          )}

          <Col sm="auto">
            <Form.Group className="mb-3">
              <Form.Label>Начало периода</Form.Label>
              <Form.Control type="date" ref={fromInputRef} required />
            </Form.Group>
          </Col>
          <Col sm="auto">
            <Form.Group className="mb-3">
              <Form.Label>Конец периода</Form.Label>
              <Form.Control type="date" ref={toInputRef} required />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="auto">
            <Form.Group>
              <Button type="submit" className="mb-2 w-100">
                Применить
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          {tickets.length > 0 && !isLoading && (
            <Transitions>
              <Table striped hover className="table-responsive sortable">
                <thead>
                  <tr>
                    <th>Заявка</th>
                    <th>Тема</th>
                    <th>Инициатор</th>
                    <th>Ответственные</th>
                    <th>Создана</th>
                    <th>Закрыта</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets.map((ticket) => (
                    <tr key={ticket._id}>
                      <td>
                        <a href={`/tickets/${ticket.num}`} target="_blank">
                          {ticket.num}
                        </a>
                      </td>
                      <td>{ticket.title}</td>
                      <td>{`${ticket.applicant.lastName} ${ticket.applicant.firstName}`}</td>
                      <td>
                        {ticket.responsibles?.map((user) => {
                          return ticket.responsibles?.length - 1 >
                            ticket.responsibles?.indexOf(user)
                            ? `${user.lastName} ${user.firstName}, `
                            : `${user.lastName} ${user.firstName}`;
                        })}
                      </td>
                      <td>{formatShortDate(ticket.createdAt)}</td>
                      <td>{formatShortDate(ticket.finishedAt)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5}>ИТОГО: </td>
                    <td data-cell="итого">{tickets.length}</td>
                  </tr>
                </tfoot>
              </Table>
            </Transitions>
          )}
          {isLoading && <Spinner />}
        </Col>
      </Row>
    </Transitions>
  );
};

export default TicketsArchive;

export async function loader() {
  document.title = "АРХИВ ЗАЯВОК";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/tickets/form-data`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}
