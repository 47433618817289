import { redirect } from "react-router-dom";

import Form from "../../components/ClientDevice/Form";
import { getLocalStorageData } from "../../util/auth";

const UpdateClientDevicePage = () => {
  return <Form title="Изменить устройство" />;
};

export default UpdateClientDevicePage;

export async function loader({ params }) {
  document.title = "Изменение устройстваы";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/inventory/client-devices/${params.id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action({ request, params }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const clientDeviceData = Object.fromEntries(data);

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/inventory/client-devices/update/${params.id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(clientDeviceData),
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect("/inventory/client-devices");
}
