import { useState, useContext } from "react";

import { formatDate } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { AuthedUserContext } from "../../store/authed-user-context";
import WorkForm from "./WorkForm";
import DeleteWork from "./Delete";
import { isBrowser } from "react-device-detect";

function WorkItem({
  work,
  works,
  otherCompanyTickets,
  ticket,
  company,
  responsibles,
  setWorks,
}) {
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const [isNew, setIsNew] = useState(
    new Date() - new Date(work.createdAt) < 10000 ? true : false,
  );

  setTimeout(() => {
    setIsNew(false);
  }, 15000);

  return (
    <>
      <Card
        className={`shadow-sm mb-3 ${isNew ? "bg-success bg-opacity-10" : ""}`}
      >
        <Card.Body>
          <Row className="mb-2">
            <Col>
              <strong>{`${work?.finishedBy?.lastName} ${work?.finishedBy?.firstName}`}</strong>{" "}
              <span className="text-body-secondary">{`${formatDate(
                work.createdAt,
              )}`}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {work.visitRequired && (
                <span>
                  Произведён <strong>выезд</strong>.{" "}
                </span>
              )}
              {!work.visitRequired && (
                <span>
                  Проведены <strong>удалённые работы</strong>.{" "}
                </span>
              )}
              {work.description}
            </Col>
          </Row>
          <Row className="justify-content-end align-items-end">
            <Col className="mb-3">
              Длительность{" "}
              <strong>
                {msToHMS(new Date(work.finishedAt) - new Date(work.startedAt))}
              </strong>
            </Col>
            {(isAdmin || work.createdBy?._id.toString() === userId) && (
              <>
                {isBrowser && (
                  <Col xs="auto" className="text-end">
                    <DeleteWork work={work} works={works} setWorks={setWorks} />
                  </Col>
                )}
                <Col xs="auto" className="text-end">
                  <WorkForm
                    title="Изменить работы"
                    buttonStyle="updateItem"
                    action={`/works/update/${work._id.toString()}`}
                    work={work}
                    ticket={ticket}
                    company={company}
                    responsibles={responsibles}
                    works={works}
                    otherCompanyTickets={otherCompanyTickets}
                  />
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default WorkItem;
