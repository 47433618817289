import {
  Form as RouterForm,
  useActionData,
  useNavigation,
  Link,
} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

import { RiSaveLine, RiArrowGoBackFill } from "react-icons/ri";

import AlertMessage from "./AlertMessage";
import Transitions from "../animations/Transition";

import useOffcanvasStore from "../store/offcanvas";

const FormWrapper = ({ title, action, children }) => {
  const data = useActionData();
  const { state } = useNavigation();
  const offcanvas = useOffcanvasStore();

  return (
    <Container>
      <Transitions>
        <RouterForm method="post" action={action ? action : "."}>
          <h1>{title}</h1>
          <hr></hr>
          {data && data.errors && (
            <AlertMessage
              variant="danger"
              message={Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            />
          )}
          {data && data.message && data.error && (
            <AlertMessage variant="danger" message={data.message} />
          )}
          {data && data.message && !data.error && (
            <AlertMessage variant="success" message={data.message} />
          )}
          {children}
          <hr></hr>
          <Stack direction="horizontal" gap={3}>
            <div className="ms-auto">
              <Button
                as={Link}
                to={-1}
                onClick={offcanvas.setClose}
                variant="secondary"
              >
                <RiArrowGoBackFill /> Закрыть
              </Button>
            </div>
            <div className="">
              <Button
                variant="primary"
                type="submit"
                disabled={state !== "idle"}
                onClick={offcanvas.setClose}
              >
                <RiSaveLine /> Сохранить
              </Button>
            </div>
          </Stack>
        </RouterForm>
      </Transitions>
    </Container>
  );
};

export default FormWrapper;
