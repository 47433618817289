import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import { getLocalStorageData } from "../util/auth";
import { getInitialPrefsData } from "../util/prefs";
import { MobileView } from "react-device-detect";

const Footer = () => {
  const { token } = getLocalStorageData();
  const { contacts } = getInitialPrefsData();
  const isLoggedIn = !!token;

  return (
    <>
      {isLoggedIn && (
        <>
          <Card className="text-center">
            <Card.Body>
              <h5>Наши контакты</h5>
              <hr></hr>
              <p>
                Телефон: <a href={`tel:${contacts.tel}`}>{contacts.tel}</a>
              </p>
              <p>
                Email: <a href="mailto:">{contacts.email}</a>
              </p>
              <p>Адрес: {contacts.address}</p>
            </Card.Body>
          </Card>
          <footer className="page-footer pt-4 mt-auto">
            <div className="text-center">
              <Link to="/changelog">Changelog</Link>
            </div>
            <div className="footer-copyright text-center py-3">
              © {new Date().getFullYear()} F1Lab Helpdesk
            </div>
            <div className="text-center pb-3">
              Версия {process.env.REACT_APP_VERSION}
            </div>
            <MobileView>
              <div style={{ marginBottom: "80px" }}></div>
            </MobileView>
          </footer>
        </>
      )}
    </>
  );
};
export default Footer;
